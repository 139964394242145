import { PREVIEW, PROJECT_LINK } from "../../project-config";

export default function footerContent(langState, isLoggedIn) {
  let langCode = langState == "en" || langState == "tr" ? "" : `/${langState}`;

  const hideIfLoggedIn = () => {
    if (isLoggedIn) return { style: { display: isLoggedIn ? "none" : "" } };
    return {};
  };

  return {
    about: [ //best to keep both about and shop list items equivalent. else they are misaligned ie: 3 each, 2 each. 
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              to: `/privacy-policy`
            },
            text: "Privacy Policy"

          },
          {
            attributes: {
              to: `/terms-of-use`
            },
            text: "DFS Dealer Terms & Conditions"
          },
          // {                
          //   attributes: {
          //     to: `/return-policy`
          //   },
          //   text: "Return Policy"
          // },
          {
            attributes: {
              to: `/customer-service-topics`
            },
            text: "Customer Service"

          }
        ]
      }
    ],

    shop: [
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              href: `mailto:printing@dfsbusiness.com` //Superflous? This is on the full static contact page too.
            },
            text: "E-mail : printing@dfsbusiness.com"
          },
          {
            attributes: {
              to: `/contact`
            },
            text: "Contact" //Formerly Contact Us. Rephrased to match DL's original.
          },
          // {
          //   attributes: {
          //     to: `/seller-registration` //Remove entirely?
          //   },
          //   // text: "Seller Sign Up"
          //   text: " "
          // }
        ]
      }
    ],
    socialMedia: [
      {
        parent: { attributes: { className: "socialMedia" } },
        children: [
          {
            attributes: {
              target: "_blank",
              href: "https://www.facebook.com/avetticommerce",
              rel : "noopener noreferrer"
            },
            image: `Facebook`
          },
          {
            attributes: {
              target: "_blank",
              href: "https://www.linkedin.com/",
              rel : "noopener noreferrer"
            },
            image: `Linkedin`
          },

          {
            attributes: {
              target: "_blank",
              href: "https://www.twitter.com/avetticom",
              rel : "noopener noreferrer"
            },
            image: `Twitter`
          }
        ]
      }
    ]
  };
}
