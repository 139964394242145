import React, { useContext, useState, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { I18nContext } from "../../../i18n/index";
const PriceTag = props => {
  const { currency, priceConvert } = React.useContext(I18nContext);
  const [priceState, setPriceState] = useState(0);

  useEffect(() => {
    if (props.value.integer && props.value.decimal) {
      setPriceState(
        // Number(props.value.integer + "." + props.value.decimal) / priceConvert
        Number(props.value.integer + "." + props.value.decimal) 
      );
    }
  }, [props]);

  return (
    <FormattedNumber value={priceState} style="currency" currency="CAD" />
  );
};

export default PriceTag;
