import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "gatsby";

import NavMenu from "./NavMenu.jsx";
import logo from "../assets/img/demoLogo_mobile1.png";
import Drawer from "@material-ui/core/Drawer";
import MiniCart from "../components/AC-MiniCart/MiniCart.jsx";
import ADAbar from "../components/AC-ADAbar/components/ADAbar";
import Profile from "./Profile.jsx";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import SearchBarMobile from "../components/AC-Search/components/SearchBarMobile";
import WishList from "../components/AC-WishList/components/WishListCustomModal.jsx";
import LanguageSelect from "../i18n/components/LanguageSelect";
import { I18nContext } from "../i18n/index";
import Async from "react-code-splitting";

import { fetchingMenuSuccess } from "../redux/actions/menuActions.js";
import { useLocation } from "@reach/router";
import {
  handleMobileOrDesktop,
  setDocumentMode
} from "../redux/actions/mainActions.js";
import { FormattedNumber } from "react-intl";
import { updateBrandsGeolocation } from "../redux/actions/geoLocationActions.js";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { setMobileSearchBtnClicked } from "../redux/actions/handlersAction.js";
import CovidBar from "./CovidBar.jsx";
import Popup from "../components/AC-Notifications/Popup/Popup.jsx";
import DisruptionBar from "./DisruptionBar.jsx";
const SearchBar = () => <Async load={import("./SearchHelper")} />;

export default function MenuTopBar({ data, brands }) {
  const dataImg = useStaticQuery(graphql`
    query {
      Logo: file(relativePath: { eq: "demoLogo_mobile1.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = useContext(I18nContext);

  useEffect(() => {
    const navCats = {
      name: "Shop",
      vid: "20090722001",
      cid: "12257",
      thumbnail: "No Image",
      image: "No Image",
      position: "1",
      description: "Shop",
      metadescription: "Shop",
      URL: "DFS/shop/",
      childs: [...data]
    };
    dispatch(fetchingMenuSuccess(navCats));

    //BRANDS GEOLOCATION
    dispatch(updateBrandsGeolocation(brands));
  }, []);
  const langCode = useSelector(state => state.mainReducer.lang, shallowEqual);

  const [loginModalOpened, setLoginModalOpened] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const suppliersBasketState = useSelector(
    state => state.sessionReducer.suppliersBasket,
    shallowEqual
  );

  const suppliersBasketCountState =
    suppliersBasketState &&
    Object.keys(suppliersBasketState).length > 0 &&
    Object.keys(suppliersBasketState).reduce((a, key) => {
      a += suppliersBasketState[key].products.length;
      return a;
    }, 0);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const documentModeState = useSelector(
    state => state.mainReducer.documentMode,
    shallowEqual
  );

  //#region screen width listener starts
  let initialMobile;
  if (typeof window !== "undefined") {
    initialMobile = window.innerWidth;
  }

  const [currentScreenWidth, setCurrentScreenWidth] = useState(initialMobile);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setCurrentScreenWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  let isMobile;
  const mobileSize = 768;
  useEffect(() => {
    if (currentScreenWidth > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    dispatch(handleMobileOrDesktop({ isMobile, currentScreenWidth }));
  }, [currentScreenWidth]);

  //#endregion screen width listener starts

  let name;

  const toggleDrawerMini = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div role="presentation" onClick={toggleDrawerMini(side, false)}>
      <MiniCart
        close={() => toggleDrawerMini(side, false)}
        access={(e, b) => toggleDrawerMini(e, b)}
        state={state}
        setState={setState}
      />
    </div>
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  // const toggleDrawersearch = () => event => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setNavMenuOpen(!navMenuOpen);
  // };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
  };

  const handleOpenModalToChangeDistance = () => {
    const button = document.getElementById("locationChangeBtn");
    button.click();
  };

  const renderMyCartTextOrTheSumOfTheCart = () => {
    if (suppliersBasketCountState > 0) {
      let sumOfAllProducts = Object.keys(suppliersBasketState).reduce(
        (a, key) => {
          a += suppliersBasketState[key].products.reduce((acc, product) => {
            acc += product.price * product.qty;
            return acc;
          }, 0);
          return a;
        },
        0
      );

      return (
        <FormattedNumber
          value={sumOfAllProducts / priceConvert}
          style="currency"
          currency={currency}
        >
          {value => <b style={{ fontSize: "1.125em" }}>{value}</b>}
        </FormattedNumber>
      );
    }
    return translate("js.header.cart");
  };

  const testElRefs = useRef([]);
  const [menuState, setMenuState] = useState(null);
  const [showMenuState, setShowMenuState] = useState("main");
  const [parentMenu, setParentMenu] = useState({
    title: "Main Menu",
    menu: "main",
    parent: [],
    subTitle: ""
  });
  let lengthCal = (data, tempObj, cid, main = false, description) => {
    data.map(dt => {
      if (main) {
        tempObj = {
          ...tempObj,
          main: [
            ...tempObj.main,
            { ...dt, return: { title: description, menu: "main" } }
          ]
        };
      } else {
        if (Object.keys(tempObj).includes(cid) && cid !== "") {
          tempObj = {
            ...tempObj,
            [cid]: [
              ...tempObj[cid],
              { ...dt, return: { title: description, menu: cid } }
            ]
          };
        } else {
          tempObj = {
            ...tempObj,
            [cid]: [{ ...dt, return: { title: description, menu: cid } }]
          };
        }
      }
      if (Object.keys(dt).includes("childs") && dt.childs.length > 0) {
        tempObj = lengthCal(dt.childs, tempObj, dt.cid, false, dt.description);
      }
    });
    return tempObj;
  };
  useEffect(() => {
    let tempObj = { main: [] };
    let tempObjReturn = lengthCal(data, tempObj, "", true, "Main Menu");
    console.log("HEYYO MENU", data, tempObjReturn);
    setMenuState(tempObjReturn);
  }, []);

  useEffect(() => {
    console.info("HEYYO MENU", parentMenu);
  }, [parentMenu]);

  const closeRef = useRef(null);
  const lastRef = useRef(null);
  const searchRef = useRef(null);
  const headRef = useRef(null);

  useLayoutEffect(() => {
    if (
      testElRefs &&
      menuState &&
      showMenuState &&
      menuState[showMenuState] &&
      menuState[showMenuState].length > 0 &&
      testElRefs.current &&
      Object.keys(menuState[showMenuState][0].cid) &&
      testElRefs.current[menuState[showMenuState][0].cid]
    ) {
      testElRefs.current[menuState[showMenuState][0].cid].focus();
    }
  }, [showMenuState]);

  const renderIEPopup = () => {
    if (
      documentModeState &&
      localStorage.getItem("ieNotificationRead") !== "true"
    ) {
      let buttons = [];

      const onCloseIconClicked = () => {
        dispatch(setDocumentMode(null));
        localStorage.setItem("ieNotificationRead", "true");
      };

      buttons.push({
        primary: true,
        title: "OK",
        onClick: () => {
          onCloseIconClicked();
        }
      });

      if (documentModeState > 10 === false) {
        buttons.push({
          title: "Download Chrome",
          onClick: () => {
            window.open("https://www.google.ca/chrome");
          }
        });
      }

      return (
        <Popup
          title="You are using Internet Explorer as your browser"
          description={
            documentModeState > 10
              ? "For a better shopper experience we recommend you use an up to date browser such as Chrome, Firefox, Safari or  Microsoft Edge."
              : "Unfortunately your browser is not supported on this site. We recommend use an up to date browser such as Chrome, Firefox, Safari or  Microsoft Edge."
          }
          buttons={buttons}
          closeIconClicked={onCloseIconClicked}
        />
      );
    }
  };

  return (
    <>
      {renderIEPopup()}

      <nav
        tabIndex={documentModeState ? "-1" : "0"}
        className="nav-extended"
        style={{ background: "#fff" }}
      >
        {navMenuOpen ? null : <ADAbar />}
        {/* <ADAbar /> */}
        <div className="nav-wrapper">
          <div className="header-container" style={{ height: "auto" }}>
            {isMobileState ? (
              <React.Fragment>
                <div className="icon-container">
                  <div className="icon-wrapper">
                    <i
                      aria-label={
                        navMenuOpen ? "Close the menu" : "Open the menu"
                      }
                      aria-hidden={navMenuOpen ? false : true}
                      ref={closeRef}
                      id="mobile-nav-icon"
                      open={navMenuOpen}
                      className="material-icons mobile-nav-icon focusIconGray"
                      tabIndex="0"
                      onClick={() => {
                        toggleDrawer();
                        setShowMenuState("main");
                        setParentMenu({
                          title: "Main Menu",
                          menu: "main",
                          parent: [],
                          subTitle: ""
                        });
                      }}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          toggleDrawer();
                          setShowMenuState("main");
                          setParentMenu({
                            title: "Main Menu",
                            menu: "main",
                            parent: [],
                            subTitle: ""
                          });
                        } else if (e.keyCode === 27) {
                          e.preventDefault();
                          toggleDrawer();
                          setShowMenuState("main");
                          setParentMenu({
                            title: "Main Menu",
                            menu: "main",
                            parent: [],
                            subTitle: ""
                          });

                          closeRef.current.focus();
                        } else if (
                          e.shiftKey &&
                          e.keyCode === 9 &&
                          navMenuOpen
                        ) {
                          e.preventDefault();
                          lastRef.current.focus();
                        }
                      }}
                    >
                      <span></span>
                    </i>
                  </div>
                </div>
                <div className="mobile-nav-bar-outer-container">
                  <div
                    className="mobile-nav-bar-container"
                    open={navMenuOpen}
                    aria-hidden={navMenuOpen ? false : true}
                  >
                    <div
                      id="nav-menu-drawer"
                      className={
                        langCode === "ar"
                          ? "rtl nav-wrapper actual-nav scroll-bar-thin-style"
                          : "nav-wrapper actual-nav scroll-bar-thin-style"
                      }
                    >
                      <div className="nav-content-wrapper">
                        <div className="search-bar-container">
                          <div className="search-bar-wrapper">
                            <SearchBarMobile
                              navMenuOpen={navMenuOpen}
                              searchRef={searchRef}
                            />
                          </div>
                        </div>
                        <ul
                          className="staticMenu"
                          role="menubar"
                          aria-labelledby="Main Menu"
                        >
                          {showMenuState === "main" ? (
                            <li style={{ color: "#2aa841" }}>
                              <Link
                                role="menuitem"
                                tabIndex={navMenuOpen ? "0" : "-1"}
                                onClick={() => {
                                  if (isMobileState) {
                                    setNavMenuOpen(!navMenuOpen);
                                  }
                                }}
                                onKeyDown={e => {
                                  if (e.keyCode === 27) {
                                    e.preventDefault();
                                    toggleDrawer();
                                    setShowMenuState("main");
                                    setParentMenu({
                                      title: "Main Menu",
                                      menu: "main",
                                      parent: [],
                                      subTitle: ""
                                    });

                                    closeRef.current.focus();
                                  } else if (e.keyCode === 40) {
                                    e.preventDefault();
                                    if (
                                      menuState[showMenuState] &&
                                      menuState[showMenuState].length > 0
                                    ) {
                                      testElRefs.current[
                                        menuState[showMenuState][0].cid
                                      ].focus();
                                    }
                                  }
                                }}
                                className="menuCat1"
                                to={"/"}
                                ref={headRef}
                              >
                                {translate("Home").toUpperCase()}
                              </Link>
                            </li>
                          ) : (
                            <li
                              style={{
                                color: "#2aa841",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                              }}
                            >
                              <i
                                className="material-icons focusIcon"
                                role="menuitem"
                                ref={headRef}
                                style={{ color: "white" }}
                                tabIndex={navMenuOpen ? "0" : "-1"}
                                onClick={e => {
                                  let parents = [...parentMenu.parent];
                                  setShowMenuState(
                                    parentMenu.parent[
                                      parentMenu.parent.length - 1
                                    ]
                                  );
                                  parents.pop();

                                  setParentMenu({
                                    ...parentMenu,
                                    menu:
                                      parentMenu.parent[
                                        parentMenu.parent.length - 1
                                      ],
                                    parent: parents
                                  });
                                }}
                                onKeyDown={e => {
                                  console.log("ESC", e.keyCode);
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    let parents = [...parentMenu.parent];
                                    setShowMenuState(
                                      parentMenu.parent[
                                        parentMenu.parent.length - 1
                                      ]
                                    );
                                    parents.pop();

                                    setParentMenu({
                                      ...parentMenu,
                                      menu:
                                        parentMenu.parent[
                                          parentMenu.parent.length - 1
                                        ],
                                      parent: parents
                                    });
                                  } else if (e.keyCode === 27) {
                                    e.preventDefault();
                                    toggleDrawer();
                                    setShowMenuState("main");
                                    setParentMenu({
                                      title: "Main Menu",
                                      menu: "main",
                                      parent: [],
                                      subTitle: ""
                                    });

                                    closeRef.current.focus();
                                  } else if (e.keyCode === 40) {
                                    e.preventDefault();
                                    if (
                                      menuState[showMenuState] &&
                                      menuState[showMenuState].length > 0
                                    ) {
                                      testElRefs.current[
                                        menuState[showMenuState][0].cid
                                      ].focus();
                                    }
                                  } else if (e.keyCode === 37) {
                                    e.preventDefault();
                                    if (showMenuState !== "main") {
                                      e.preventDefault();
                                      let parents = [...parentMenu.parent];
                                      setShowMenuState(
                                        parentMenu.parent[
                                          parentMenu.parent.length - 1
                                        ]
                                      );
                                      parents.pop();

                                      setParentMenu({
                                        ...parentMenu,
                                        menu:
                                          parentMenu.parent[
                                            parentMenu.parent.length - 1
                                          ],
                                        parent: parents
                                      });
                                    }
                                  }
                                }}
                              >
                                chevron_left
                              </i>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    parentMenu.menu === "main" ||
                                    parentMenu.parent.length === 1
                                      ? "Main Menu"
                                      : parentMenu.subTitle
                                }}
                              />
                            </li>
                          )}

                          {menuState !== null &&
                            menuState[showMenuState] &&
                            menuState[showMenuState].length > 0 &&
                            menuState[showMenuState].map((cat, index) => {
                              let catName = cat.description;
                              let tempUrl = cat.URL;
                              tempUrl = tempUrl;

                              tempUrl = tempUrl.replace(
                                `${langCode}/shop`,
                                "shop"
                              );

                              tempUrl = tempUrl.replace(`shop`, "");
                              return (
                                <li
                                  key={cat.cid}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                  }}
                                  role="menuitem"
                                >
                                  <Link
                                    ref={node =>
                                      (testElRefs.current[cat.cid] = node)
                                    }
                                    tabIndex={navMenuOpen ? "0" : "-1"}
                                    onClick={() =>
                                      handleCategoryChange(
                                        cat.cid,
                                        catName,
                                        [[catName, cat.cid, cat.URL]],
                                        ""
                                      )
                                    }
                                    onKeyDown={e => {
                                      if (e.keyCode === 27) {
                                        e.preventDefault();
                                        toggleDrawer();
                                        setShowMenuState("main");
                                        setParentMenu({
                                          title: "Main Menu",
                                          menu: "main",
                                          parent: [],
                                          subTitle: ""
                                        });

                                        closeRef.current.focus();
                                      } else if (
                                        e.keyCode === 39 &&
                                        Object.keys(cat).includes("childs") &&
                                        cat.childs.length > 0
                                      ) {
                                        e.preventDefault();
                                        if (showMenuState !== "main") {
                                          setParentMenu({
                                            ...parentMenu,
                                            title: cat.description
                                              .split("&amp;eacute;")
                                              .join("�")
                                              .split("&#39;")
                                              .join("'"),
                                            menu: showMenuState,
                                            parent: [
                                              ...parentMenu.parent,
                                              showMenuState
                                            ]
                                          });
                                        } else {
                                          setParentMenu({
                                            ...parentMenu,
                                            subTitle: cat.description
                                              .split("&amp;eacute;")
                                              .join("�")
                                              .split("&#39;")
                                              .join("'"),
                                            parent: [
                                              ...parentMenu.parent,
                                              showMenuState
                                            ]
                                          });
                                        }
                                        setShowMenuState(cat.cid);
                                      } else if (
                                        e.keyCode === 40 &&
                                        menuState[showMenuState][index + 1] &&
                                        Object.keys(
                                          menuState[showMenuState][index + 1]
                                        ).includes("cid")
                                      ) {
                                        if (
                                          testElRefs.current[
                                            menuState[showMenuState][index + 1]
                                              .cid
                                          ]
                                        ) {
                                          testElRefs.current[
                                            menuState[showMenuState][index + 1]
                                              .cid
                                          ].focus();
                                        }
                                      } else if (e.keyCode === 38) {
                                        if (
                                          index === 0 &&
                                          headRef &&
                                          headRef.current
                                        ) {
                                          headRef.current.focus();
                                        } else if (
                                          menuState[showMenuState][index - 1] &&
                                          Object.keys(
                                            menuState[showMenuState][index - 1]
                                          ).includes("cid")
                                        ) {
                                          if (
                                            testElRefs.current[
                                              menuState[showMenuState][
                                                index - 1
                                              ].cid
                                            ]
                                          ) {
                                            testElRefs.current[
                                              menuState[showMenuState][
                                                index - 1
                                              ].cid
                                            ].focus();
                                          }
                                        }
                                      } else if (e.keyCode === 37) {
                                        e.preventDefault();
                                        if (showMenuState !== "main") {
                                          e.preventDefault();
                                          let parents = [...parentMenu.parent];
                                          setShowMenuState(
                                            parentMenu.parent[
                                              parentMenu.parent.length - 1
                                            ]
                                          );
                                          parents.pop();

                                          setParentMenu({
                                            ...parentMenu,
                                            menu:
                                              parentMenu.parent[
                                                parentMenu.parent.length - 1
                                              ],
                                            parent: parents
                                          });
                                        }
                                      }
                                    }}
                                    className="menuCat"
                                    to={"/" + tempUrl}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: catName
                                      }}
                                    />
                                  </Link>
                                  {Object.keys(cat).includes("childs") &&
                                    cat.childs &&
                                    cat.childs.length > 0 && (
                                      <i
                                        className="material-icons focusIcon"
                                        style={{ color: "white" }}
                                        tabIndex={navMenuOpen ? "0" : "-1"}
                                        onClick={e => {
                                          if (showMenuState !== "main") {
                                            setParentMenu({
                                              ...parentMenu,
                                              title: cat.description,
                                              menu: showMenuState,
                                              parent: [
                                                ...parentMenu.parent,
                                                showMenuState
                                              ]
                                            });
                                          } else {
                                            setParentMenu({
                                              ...parentMenu,
                                              subTitle: cat.description,
                                              parent: [
                                                ...parentMenu.parent,
                                                showMenuState
                                              ]
                                            });
                                          }
                                          setShowMenuState(cat.cid);
                                        }}
                                        onKeyDown={e => {
                                          if (e.keyCode === 13) {
                                            e.preventDefault();
                                            if (showMenuState !== "main") {
                                              setParentMenu({
                                                ...parentMenu,
                                                title: cat.description,
                                                menu: showMenuState,
                                                parent: [
                                                  ...parentMenu.parent,
                                                  showMenuState
                                                ]
                                              });
                                            } else {
                                              setParentMenu({
                                                ...parentMenu,
                                                subTitle: cat.description,
                                                parent: [
                                                  ...parentMenu.parent,
                                                  showMenuState
                                                ]
                                              });
                                            }
                                            setShowMenuState(cat.cid);
                                          } else if (e.keyCode === 27) {
                                            e.preventDefault();
                                            toggleDrawer();
                                            setShowMenuState("main");
                                            setParentMenu({
                                              title: "Main Menu",
                                              menu: "main",
                                              parent: [],
                                              subTitle: ""
                                            });

                                            closeRef.current.focus();
                                          }
                                        }}
                                      >
                                        chevron_right
                                      </i>
                                    )}
                                </li>
                              );
                            })}
                        </ul>
                        <ul className="staticMenu">
                          <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "login-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                e.preventDefault();

                                toggleDrawer();
                                const btn = document.getElementById(
                                  "login-icon-btn"
                                );
                                if (btn) btn.click();
                              } else if (e.keyCode === 27) {
                                e.preventDefault();
                                toggleDrawer();
                                setShowMenuState("main");
                                setParentMenu({
                                  title: "Main Menu",
                                  menu: "main",
                                  parent: [],
                                  subTitle: ""
                                });

                                closeRef.current.focus();
                              }
                            }}
                            tabIndex={navMenuOpen ? "0" : "-1"}
                          >
                            <span>
                              {loginNameState
                                ? "My Account"
                                : translate("js.header.login").toUpperCase()}
                            </span>
                          </li>

                          <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "cart-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                toggleDrawer();
                                const btn = document.getElementById(
                                  "cart-icon-btn"
                                );
                                if (btn) btn.click();
                              } else if (e.keyCode === 27) {
                                e.preventDefault();
                                toggleDrawer();
                                setShowMenuState("main");
                                setParentMenu({
                                  title: "Main Menu",
                                  menu: "main",
                                  parent: [],
                                  subTitle: ""
                                });

                                closeRef.current.focus();
                              } else if (!e.shiftKey && e.keyCode === 9) {
                                e.preventDefault();
                                closeRef.current.focus();
                              }
                            }}
                            tabIndex={navMenuOpen ? "0" : "-1"}
                            ref={lastRef}
                          >
                            <span>MY CART</span>
                          </li>
                          {/*   <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "wishlist-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                          >
                            <span>MY WISHLIST</span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            <div className="logo-container">
              <Link to={"/"} className="brand-logo logo-i">
                <div className="menu-logo-wrapper">
                  <img className="" src={logo} alt="DFS Logo" />
                </div>
              </Link>
            </div>

            {isMobileState ? null : <SearchBar />}

            <div
              onClick={() => {
                navMenuOpen && setNavMenuOpen(false);
              }}
              className="nav-icons-container"
            >
              <div className="nav-icons-container">
                <div className="icon-container">
                  <LanguageSelect />
                </div>
                {!isMobileState ? null : (
                  <div className="icon-container seller-icon-container">
                    <div className="icon-wrapper">
                      <i
                        //id="mobile-nav-icon"
                        open={navMenuOpen}
                        className="material-icons mobile-nav-icon focusIconGray"
                        onClick={() => {
                          toggleDrawer();
                          dispatch(setMobileSearchBtnClicked(true));
                        }}
                        onKeyDown={e => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            toggleDrawer();
                            dispatch(setMobileSearchBtnClicked(true));
                          }
                        }}
                        tabIndex="0"
                      >
                        search
                      </i>
                    </div>
                  </div>
                )}

                <div
                  className="icon-container login-icon-container"
                  // onClick={event => handleLoginIconClicked(event)}
                >
                  <Profile
                    modalOpenedByIcon={loginModalOpened}
                    setModalOpened={setLoginModalOpened}
                  />
                </div>

                <WishList />

                {/* <div className="icon-wrapper" onClick={
                    langCode === "ar"
                      ? toggleDrawerMini("left", true)
                      : toggleDrawerMini("right", true)
                  }>
                      <i className="im im-icon-Shopping-Bag"></i>            
                   
                  </div> */}
                <div
                  onClick={
                    langCode === "ar"
                      ? toggleDrawerMini("left", true)
                      : toggleDrawerMini("right", true)
                  }
                  onKeyPress={
                    langCode === "ar"
                      ? toggleDrawerMini("left", true)
                      : toggleDrawerMini("right", true)
                  }
                  className="icon-text"
                  id="cart-icon-btn"
                  tabIndex="0"
                >
                  <span class="material-icons i">shopping_cart</span>
                  <span className="icon-action-text"></span>
                </div>

                <Drawer
                  anchor={langCode === "ar" ? "left" : "right"}
                  open={langCode === "ar" ? state.left : state.right}
                  onClose={
                    langCode === "ar"
                      ? toggleDrawerMini("left", false)
                      : toggleDrawerMini("right", false)
                  }
                >
                  {langCode === "ar" ? sideList("left") : sideList("right")}
                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav
        className={isMobileState ? "nav-extended hidden" : "nav-extended"}
        style={{
          boxShadow: "none",
          background: "#fff",
          padding: "3px 0px",
          borderTop: "1px solid #cdcdcd",
          borderBottom: "1px solid #cdcdcd"
        }}
      >
        <div className="nav-wrapper">
          <div className="header-container">
            <NavMenu data={data} />
          </div>
        </div>
      </nav>
      {/*   <CovidBar /> */}
      {/* <DisruptionBar /> */}
    </>
  );
}
