import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  createRef
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "gatsby";
import {
  LINK_DISTRIBUTION,
  PREVIEW,
  PROJECT_LINK,
  VID
} from "../project-config.js";
import { I18nContext } from "../i18n/index";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import SearchBarMobile from "../components/AC-Search/components/SearchBarMobile";

import "./Styles/header.css";
import "./Styles/NavMenu.css";

export default function NavMenu({ data }) {
  const { langCode, translate } = useContext(I18nContext);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const currentScreenWidthState = useSelector(
    state => state.mainReducer.currentScreenWidth,
    shallowEqual
  );

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = () => event => {
    if (event) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    // let catName = cat;
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
  };

  const [elRefs, setElRefs] = useState({});

  const testElRefs = useRef([]);
  const [hoverRef, setHoverRef] = useState([]);
  const [firstLevel, setFirstLevel] = useState("");
  const homeRef = useRef(null);
  let lengthCal = (data, tempObj) => {
    data.map(dt => {
      tempObj[dt.cid] = createRef();

      setHoverRef({ ...hoverRef, [dt.cid]: false });

      if (Object.keys(dt).includes("childs") && dt.childs.length > 0) {
        lengthCal(dt.childs, tempObj);
      }
    });
    return tempObj;
  };
  useEffect(() => {
    let tempObj = {};
    let tempObjReturn = lengthCal(data, tempObj);
    console.info("REFS *****************", data, tempObjReturn);
    setElRefs({ ...tempObjReturn });
  }, []);

  useEffect(() => {
    console.info("FIRST CHECK", firstLevel);
  }, [firstLevel]);

  const renderNavLinks = (
    cats,
    subCat = false,
    thirdLevel = false,
    prevUrl = null,
    catCid = "",
    mainIndex = 0,
    parentCatCid = ""
  ) => {
    return (
      cats &&
      cats.map((cat, index) => {
        let insideIndex = mainIndex;
        if (catCid === "") {
          insideIndex = index;
        }
        let catName = cat.description;
        let arrURL = cat.URL.split(`/`);
        let lastURL = arrURL[arrURL.length - 1] || arrURL[arrURL.length - 2];
        let className = `navlink-${lastURL}`;
        let tempUrl = cat.URL.replace("shop/", "");
        let actualUrl = null;
        if (tempUrl === "dealer-account-registration/") {
          actualUrl =
            LINK_DISTRIBUTION +
            `/register.html?vid=${VID}&mt=1&ml=${languageState}`;
        }
        let langTemp = tempUrl.includes(langCode) ? "" : `${langCode}/`;
        console.info("prevUrl", prevUrl, tempUrl);
        let thirdLevelCheck = !!subCat;
        if (cat.name.toLowerCase() === "stamps") {
          console.log("MENU INVESTIGATION", cat, subCat, thirdLevel, catCid);
        }
        return (
          <>
            <li
              style={{ cursor: "pointer" }}
              key={cat.cid}
              className={`navlink ${className}`}
              role="menuitem"
            >
              {tempUrl === "dealer-account-registration/" ? (
                <a
                  className={subCat ? "" : "main-cate"}
                  href={
                    actualUrl
                      ? actualUrl
                      : langCode !== "en"
                      ? "/" + langTemp + tempUrl
                      : "/" + tempUrl
                  }
                  ref={node =>
                    (testElRefs.current[`${cat.cid}${catCid}`] = node)
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 40 && !e.shiftKey) {
                      e.preventDefault();
                      if (catCid === "") {
                        if (
                          testElRefs.current[`${cat.childs[0].cid}${cat.cid}`]
                        ) {
                          testElRefs.current[
                            `${cat.childs[0].cid}${cat.cid}`
                          ].focus();
                        }
                      } else if (
                        cats[index + 1] &&
                        Object.keys(cats[index + 1]).includes("cid")
                      ) {
                        if (
                          testElRefs.current[`${cats[index + 1].cid}${catCid}`]
                        ) {
                          testElRefs.current[
                            `${cats[index + 1].cid}${catCid}`
                          ].focus();
                        }
                      } else if (
                        index === cats.length - 1 &&
                        catName.includes("Quickbooks Cheques")
                      ) {
                        testElRefs.current["custom-cheque"].focus();
                      }
                    } else if (e.keyCode === 38 && !e.shiftKey) {
                      e.preventDefault();
                      if (catCid === "") {
                      } else if (
                        cats[index - 1] &&
                        Object.keys(cats[index - 1]).includes("cid")
                      ) {
                        if (
                          testElRefs.current[`${cats[index - 1].cid}${catCid}`]
                        ) {
                          testElRefs.current[
                            `${cats[index - 1].cid}${catCid}`
                          ].focus();
                        }
                      }
                    } else if (e.keyCode === 39 && !e.shiftKey) {
                      if (
                        catCid !== "" &&
                        Object.keys(cat).includes("childs") &&
                        cat.childs.length > 0 &&
                        Object.keys(cat.childs[0]).includes("cid") &&
                        testElRefs.current[`${cat.childs[0].cid}${cat.cid}`]
                      ) {
                        testElRefs.current[
                          `${cat.childs[0].cid}${cat.cid}`
                        ].focus();
                      } else if (catCid === "" && index < cats.length - 1) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj).map(l => (tempObj[l] = false));
                        setHoverRef({ ...tempObj });
                        testElRefs.current[cats[index + 1].cid].focus();
                      }
                    } else if (e.keyCode === 37 && !e.shiftKey) {
                      if (
                        catCid !== "" &&
                        testElRefs.current[`${catCid}${parentCatCid}`]
                      ) {
                        testElRefs.current[`${catCid}${parentCatCid}`].focus();
                      } else if (index !== 0) {
                        testElRefs.current[
                          `${cats[index - 1].cid}${catCid}`
                        ].focus();
                      } else if (index === 0 && homeRef && homeRef.current) {
                        homeRef.current.focus();
                      }
                    }
                    if (
                      (e.keyCode === 40 || e.keyCode === 39) &&
                      Object.keys(cat).includes("childs") &&
                      cat.childs.length > 0
                    ) {
                      e.preventDefault();
                      if (catCid === "" && e.keyCode === 40) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj).map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = true;
                        setHoverRef({ ...tempObj });
                      } else if (catCid !== "" && e.keyCode === 39) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj)
                          .filter(f => f !== catCid)
                          .map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = true;
                        setHoverRef({ ...tempObj });
                      }
                    } else if (
                      (e.keyCode === 38 || e.keyCode === 37) &&
                      !thirdLevel
                    ) {
                      e.preventDefault();
                      if (catCid === "" && e.keyCode === 38) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj).map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = false;
                        setHoverRef({ ...tempObj });
                      } else if (e.keyCode === 37) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj)
                          .filter(f => f !== catCid)
                          .map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = false;
                        setHoverRef({ ...tempObj });
                      }
                    } else if (e.shiftKey && e.keyCode === 9) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });

                      if (insideIndex !== 0) {
                        testElRefs.current[
                          `${data[insideIndex - 1].cid}`
                        ].focus();
                      } else {
                        homeRef.current.focus();
                      }
                    } else if (
                      !e.shiftKey &&
                      e.keyCode === 9 &&
                      data.length - 1 >= insideIndex + 1
                    ) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[
                        `${data[insideIndex + 1].cid}`
                      ].focus();
                    } else if (e.keyCode === 27) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };

                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[
                        `${data[insideIndex].cid}${catCid}`
                      ].focus();
                    } else if (e.keyCode !== 9 && e.keyCode !== 13) {
                      e.preventDefault();
                    }
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: catName.replace(/&amp;/gm, "&")
                    }}
                  />
                  {cat.childs && cat.childs.length > 0 ? (
                    !subCat ? (
                      <i className="material-icons">expand_more</i>
                    ) : (
                      <i className="material-icons">chevron_right</i>
                    )
                  ) : null}
                </a>
              ) : (
                <Link
                  onClick={() => handleCategoryChange()}
                  ref={node =>
                    (testElRefs.current[`${cat.cid}${catCid}`] = node)
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 40 && !e.shiftKey) {
                      e.preventDefault();
                      if (catCid === "") {
                        if (
                          testElRefs.current[`${cat.childs[0].cid}${cat.cid}`]
                        ) {
                          testElRefs.current[
                            `${cat.childs[0].cid}${cat.cid}`
                          ].focus();
                        }
                      } else if (
                        cats[index + 1] &&
                        Object.keys(cats[index + 1]).includes("cid")
                      ) {
                        if (
                          testElRefs.current[`${cats[index + 1].cid}${catCid}`]
                        ) {
                          testElRefs.current[
                            `${cats[index + 1].cid}${catCid}`
                          ].focus();
                        }
                      } else if (
                        index === cats.length - 1 &&
                        catName.includes("Quickbooks Cheques")
                      ) {
                        testElRefs.current["custom-cheque"].focus();
                      }
                    } else if (e.keyCode === 38 && !e.shiftKey) {
                      e.preventDefault();
                      if (catCid === "") {
                      } else if (
                        cats[index - 1] &&
                        Object.keys(cats[index - 1]).includes("cid")
                      ) {
                        if (
                          testElRefs.current[`${cats[index - 1].cid}${catCid}`]
                        ) {
                          testElRefs.current[
                            `${cats[index - 1].cid}${catCid}`
                          ].focus();
                        }
                      }
                    } else if (e.keyCode === 39 && !e.shiftKey) {
                      if (
                        catCid !== "" &&
                        Object.keys(cat).includes("childs") &&
                        cat.childs.length > 0 &&
                        Object.keys(cat.childs[0]).includes("cid") &&
                        testElRefs.current[`${cat.childs[0].cid}${cat.cid}`]
                      ) {
                        testElRefs.current[
                          `${cat.childs[0].cid}${cat.cid}`
                        ].focus();
                      } else if (catCid === "" && index < cats.length - 1) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj).map(l => (tempObj[l] = false));
                        setHoverRef({ ...tempObj });
                        testElRefs.current[cats[index + 1].cid].focus();
                      }
                    } else if (e.keyCode === 37 && !e.shiftKey) {
                      if (
                        catCid !== "" &&
                        testElRefs.current[`${catCid}${parentCatCid}`]
                      ) {
                        testElRefs.current[`${catCid}${parentCatCid}`].focus();
                      } else if (index !== 0) {
                        testElRefs.current[
                          `${cats[index - 1].cid}${catCid}`
                        ].focus();
                      } else if (index === 0 && homeRef && homeRef.current) {
                        homeRef.current.focus();
                      }
                    }
                    if (
                      (e.keyCode === 40 || e.keyCode === 39) &&
                      Object.keys(cat).includes("childs") &&
                      cat.childs.length > 0
                    ) {
                      e.preventDefault();
                      if (catCid === "" && e.keyCode === 40) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj).map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = true;
                        setHoverRef({ ...tempObj });
                      } else if (catCid !== "" && e.keyCode === 39) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj)
                          .filter(f => f !== catCid)
                          .map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = true;
                        setHoverRef({ ...tempObj });
                      }
                    } else if (
                      (e.keyCode === 38 || e.keyCode === 37) &&
                      !thirdLevel
                    ) {
                      e.preventDefault();
                      if (catCid === "" && e.keyCode === 38) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj).map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = false;
                        setHoverRef({ ...tempObj });
                      } else if (e.keyCode === 37) {
                        let tempObj = { ...hoverRef };
                        Object.keys(tempObj)
                          .filter(f => f !== catCid)
                          .map(l => (tempObj[l] = false));
                        tempObj[cat.cid] = false;
                        setHoverRef({ ...tempObj });
                      }
                    } else if (e.shiftKey && e.keyCode === 9) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });

                      if (insideIndex !== 0) {
                        testElRefs.current[
                          `${data[insideIndex - 1].cid}`
                        ].focus();
                      } else {
                        homeRef.current.focus();
                      }
                    } else if (
                      !e.shiftKey &&
                      e.keyCode === 9 &&
                      data.length - 1 >= insideIndex + 1
                    ) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[
                        `${data[insideIndex + 1].cid}`
                      ].focus();
                    } else if (e.keyCode === 27) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };

                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[
                        `${data[insideIndex].cid}${catCid}`
                      ].focus();
                    } else if (e.keyCode !== 9 && e.keyCode !== 13) {
                      e.preventDefault();
                    }
                  }}
                  className={subCat ? "" : `main-cate`}
                  to={
                    actualUrl
                      ? actualUrl
                      : langCode !== "en"
                      ? "/" + langTemp + tempUrl
                      : "/" + tempUrl
                  }
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: catName.replace(/&amp;/gm, "&")
                    }}
                  />
                  {cat.childs && cat.childs.length > 0 ? (
                    !subCat ? (
                      <i className="material-icons">expand_more</i>
                    ) : (
                      <i className="material-icons">chevron_right</i>
                    )
                  ) : null}
                </Link>
              )}
              {cat.childs && cat.childs.length > 0 ? (
                <ul
                  className={
                    thirdLevelCheck
                      ? `third-level-menu ${
                          hoverRef[cat.cid] ? "displayAccessible" : ""
                        }`
                      : `second-level-menu ${
                          hoverRef[cat.cid] ? "displayAccessible" : ""
                        }`
                  }
                  role="submenu"
                >
                  {renderNavLinks(
                    cat.childs,
                    true,
                    thirdLevelCheck,
                    tempUrl,
                    cat.cid,
                    insideIndex,
                    catCid
                  )}
                </ul>
              ) : null}
            </li>
            {thirdLevel &&
            index === cats.length - 1 &&
            catName.includes("Quickbooks Cheques") ? (
              <li
                style={{ cursor: "pointer" }}
                className={`navlink ${className}`}
              >
                <Link
                  onClick={() => handleCategoryChange()}
                  to={"/products/custom-printing/"}
                  ref={node => (testElRefs.current["custom-cheque"] = node)}
                  onKeyDown={e => {
                    if (e.keyCode === 40 && !e.shiftKey) {
                      e.preventDefault();
                    } else if (e.keyCode === 38 && !e.shiftKey) {
                      e.preventDefault();

                      testElRefs.current[`${cats[index].cid}${catCid}`].focus();
                    } else if (e.keyCode === 39 && !e.shiftKey) {
                      e.preventDefault();
                    } else if (e.keyCode === 37 && !e.shiftKey) {
                      if (
                        catCid !== "" &&
                        testElRefs.current[`${catCid}${parentCatCid}`]
                      ) {
                        testElRefs.current[`${catCid}${parentCatCid}`].focus();
                      } else if (index !== 0) {
                        testElRefs.current[
                          `${cats[index - 1].cid}${catCid}`
                        ].focus();
                      } else if (index === 0 && homeRef && homeRef.current) {
                        homeRef.current.focus();
                      }
                    }
                    if (e.shiftKey && e.keyCode === 9) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });

                      if (insideIndex !== 0) {
                        testElRefs.current[data[insideIndex - 1].cid].focus();
                      } else {
                        homeRef.current.focus();
                      }
                    } else if (
                      !e.shiftKey &&
                      e.keyCode === 9 &&
                      data.length - 1 > insideIndex + 1
                    ) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[data[insideIndex + 1].cid].focus();
                    } else if (e.keyCode === 27) {
                      e.preventDefault();
                      let tempObj = { ...hoverRef };

                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[data[insideIndex].cid].focus();
                    } else if (e.keyCode !== 9 && e.keyCode !== 13) {
                      e.preventDefault();
                    }
                  }}
                >
                  <span>Custom Cheques</span>
                </Link>
              </li>
            ) : null}
          </>
        );
      })
    );
  };

  const renderStoreLinks = stores => {
    return (
      <div className="navlink-sublinks-container">
        {stores.map((store, index) => {
          let storeLink = store.replace(/ /g, "-").toLowerCase();
          return (
            <Link
              className="icons-container"
              key={index}
              to={
                langCode !== "en"
                  ? PREVIEW + "/" + langCode + `/stores/${storeLink}`
                  : PREVIEW + `/stores/${storeLink}`
              }
            >
              {store}
            </Link>
          );
        })}
      </div>
    );
  };
  return (
    <nav className="categoryStructure">
      {isMobileState ? (
        <React.Fragment>
          <i
            open={navMenuOpen}
            className="material-icons mobile-nav-icon mobileDisplayy"
            onClick={toggleDrawer()}
          >
            <span></span>
          </i>
          <div className="mobile-nav-bar-container" open={navMenuOpen}>
            <div
              id="nav-menu-drawer"
              className={
                langCode === "ar"
                  ? "rtl nav-wrapper actual-nav scroll-bar-thin-style"
                  : "nav-wrapper actual-nav scroll-bar-thin-style"
              }
            >
              <div className="nav-content-wrapper">
                <div className="search-bar-container">
                  <div className="search-bar-wrapper">
                    <SearchBarMobile />
                  </div>
                </div>
                <ul className="staticMenu">
                  <li style={{ color: "#2aa841" }}>
                    <Link
                      onClick={() => {
                        if (isMobileState) {
                          setNavMenuOpen(!navMenuOpen);
                        }
                      }}
                      className="menuCat1"
                      to={"/"}
                    >
                      {translate("Home").toUpperCase()}
                    </Link>
                  </li>
                  {navCatsState &&
                    Object.keys(navCatsState).includes("childs") &&
                    navCatsState.childs.length > 0 &&
                    navCatsState.childs.map(cat => {
                      let catName = cat.name.replace("&amp;", "&");
                      let tempUrl = cat.URL;
                      tempUrl = tempUrl;

                      tempUrl = tempUrl.replace(`${langCode}/shop`, "shop");
                      return (
                        <li key={cat.cid}>
                          <Link
                            onClick={() =>
                              handleCategoryChange(
                                cat.cid,
                                catName,
                                [[catName, cat.cid, cat.URL]],
                                cat.longdesc
                              )
                            }
                            className="menuCat"
                            style={{
                              textTransform:
                                catName != "by Brand" && catName != "by Style"
                                  ? ""
                                  : null
                            }}
                            to={"/" + tempUrl}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: catName
                              }}
                            />
                            <i className="material-icons">chevron_right</i>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                <ul className="staticMenu">
                  <li>
                    <a href="https://www.avetticommerce.com/" target="_blank">
                      {translate("vm.footer_about").toUpperCase()}
                    </a>
                  </li>
                  <li>
                    <Link onClick={toggleDrawer()} to={"/login"}>
                      {translate("js.header.login").toUpperCase()}
                    </Link>
                  </li>
                  {/*  <li>
                    <a
                      href={`${PROJECT_LINK}/my-account.html?mode=customerinfo&vid=${VID}`}
                    >
                      {translate("js.header.login").toUpperCase()}
                    </a>
                </li> */}
                  <li>
                    <a
                      href={`${PROJECT_LINK}/basket.html?vid=${VID}&iu=${languageState}`}
                    >
                      {translate("js.header.cart").toUpperCase()}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${PROJECT_LINK}/signin.html?vid=${VID}&mt=1&iu=${languageState}`}
                    >
                      {translate("Myfavourites").toUpperCase()}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="ab">
          <ul className="cate top-level-menu">
            <li className="navlink">
              <Link
                className="main-cate"
                to="/"
                ref={homeRef}
                onKeyDown={e => {
                  if (!e.shiftKey && e.keyCode === 39) {
                    testElRefs.current[data[0].cid].focus();
                  }
                }}
              >
                Home
              </Link>
            </li>
            {renderNavLinks(data)}
          </ul>
        </div>
      )}
    </nav>
  );
}
