import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PROJECT_LINK, PREVIEW } from "../../../project-config";
import classes from "../styles/ADAbar.module.css";
import { Link, navigate } from "gatsby";

import { FormattedNumber } from "react-intl";
import { I18nContext } from "../../../i18n/index";
import PriceTag from "../../../shared/components/PriceTag/PriceTag";
import { handleReplaceImagesWithLargeImagesOnError } from "../../../functions/Utilities";

export default function ADAbar({ show, close, toggleWish }) {
  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const { langCode, currency, translate, priceConvert } = React.useContext(
    I18nContext
  );

  return (
    <div onClick={close} className={classes.adaCont}>
      <div className="h-con" style={{ width: "100%", textAlign: "center" }}>
        If you experience difficulty accessing content, please contact us at 
        800-490-6327 or{" "}
        <a href="mailto:printing@DFSbusiness.com?subject=Accessibility Support">
          printing@DFSbusiness.com
        </a>
        , and we will assist you.{" "}
      </div>
      {/* <div className="h-con" style={{width:"10%", paddingLeft:"20px"}}> <button>X</button>  </div> */}
    </div>
  );
}
